"use client";

import classNames from "classnames";
import { useEffect, useState } from "react";
import { PiListChecksFill } from "react-icons/pi";
import { Shape } from "../../../components/shapes";
import { AiOutlineApi, AiOutlineArrowDown } from "../../../react-icons/ai";
import { BiBlock, BiNoEntry, BiRename, BiSortDown } from "../../../react-icons/bi";
import {
  BsArchive,
  BsArrowLeft,
  BsArrowRight,
  BsArrowsAngleContract,
  BsBellFill,
  BsBellSlashFill,
  BsBuildings,
  BsBuildingsFill,
  BsCheckAll,
  BsCheckLg,
  BsDoorOpen,
  BsFillCalendarFill,
  BsFillClipboard2CheckFill,
  BsFillEraserFill,
  BsFillFileEarmarkPdfFill,
  BsFillTelephoneFill,
  BsFlagFill,
  BsGearFill,
  BsGraphUp,
  BsPeopleFill,
} from "../../../react-icons/bs";
import { CiBookmark } from "../../../react-icons/ci";
import {
  FaAddressCard,
  FaBook,
  FaCity,
  FaCommentDots,
  FaCopy,
  FaDivide,
  FaFileInvoiceDollar,
  FaFilter,
  FaGraduationCap,
  FaGripHorizontal,
  FaKey,
  FaMinus,
  FaMoneyBillWaveAlt,
  FaPlay,
  FaPlug,
  FaPlus,
  FaPowerOff,
  FaRegStopCircle,
  FaSave,
  FaSearch,
  FaTag,
  FaTimes,
  FaUser,
  FaUserCog,
  FaUsers,
} from "../../../react-icons/fa";
import { FaBuildingColumns, FaFolder, FaLink, FaLinkSlash, FaWandMagicSparkles } from "../../../react-icons/fa6";
import { FiDownload, FiEdit } from "../../../react-icons/fi";
import { GrAnnounce, GrHelpBook, GrUpdate } from "../../../react-icons/gr";
import { HiMail } from "../../../react-icons/hi";
import { HiEye, HiIdentification, HiLockClosed, HiLockOpen, HiOutlineBuildingLibrary, HiOutlineTrash } from "../../../react-icons/hi2";
import { ImAttachment } from "../../../react-icons/im";
import { IoIosAlarm, IoIosSend, IoIosSettings, IoIosWarning, IoMdMailUnread } from "../../../react-icons/io";
import { IoBarChart, IoBarcodeOutline, IoClose, IoDocumentOutline, IoLocationSharp, IoMenu, IoShareSocial, IoStorefrontSharp, IoWallet } from "../../../react-icons/io5";
import { LiaExchangeAltSolid } from "../../../react-icons/lia";
import { LuCalendar, LuCalendarDays, LuCopyPlus, LuExternalLink, LuRefreshCcw, LuReply, LuReplyAll, LuUpload } from "../../../react-icons/lu";
import { MdAddCircle, MdHistory, MdOutlineComputer, MdOutlineSettingsBackupRestore, MdOutlineSummarize, MdPassword, MdQuestionMark } from "../../../react-icons/md";
import { PiGitMerge, PiMicrosoftWordLogoFill, PiMinusCircleFill } from "../../../react-icons/pi";
import { RiArrowGoBackLine, RiMenuFoldLine, RiMenuSearchLine, RiTestTubeFill } from "../../../react-icons/ri";
import { SiMaildotru } from "../../../react-icons/si";
import { TbBinaryTree, TbDiscount2, TbListCheck, TbPower } from "../../../react-icons/tb";
import { TiSortAlphabetically } from "../../../react-icons/ti";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import LogoSyndemosWithText from "../svg/LogoSyndemosWithText";
import LogoSyndemosWithoutText from "../svg/LogoSyndemosWithoutText";

export type IconList =
  | "door"
  | "helpBook"
  | "stopCircle"
  | "calendar"
  | "identification"
  | "marker"
  | "question"
  | "check"
  | "close"
  | "attachment"
  | "trash"
  | "edit"
  | "phone"
  | "mail"
  | "file"
  | "computer"
  | "archive"
  | "restore"
  | "accountingFirm"
  | "location"
  | "address"
  | "city"
  | "minus"
  | "add"
  | "country"
  | "code"
  | "rythm"
  | "money"
  | "user"
  | "double-check"
  | "name"
  | "mail-sended"
  | "password-changed"
  | "menu"
  | "menu-fold"
  | "menu-search"
  | "stop"
  | "lock"
  | "lock-open"
  | "power-off"
  | "download"
  | "import"
  | "eraser"
  | "erease"
  | "warning"
  | "details"
  | "send"
  | "save"
  | "configuration"
  | "copy"
  | "copyText"
  | "return"
  | "arrow-down"
  | "previous"
  | "next"
  | "comment"
  | "pdf"
  | "date"
  | "plug"
  | "notification"
  | "noNotification"
  | "pending"
  | "documentation"
  | "toProcess"
  | "history"
  | "alarm"
  | "sort"
  | "shop"
  | "tag"
  | "external-link"
  | "link"
  | "unlink"
  | "people"
  | "setting"
  | "discount"
  | "eye"
  | "update"
  | "refresh"
  | "regularisation"
  | "current"
  | "search"
  | "surcharges"
  | "merge"
  | "upload"
  | "filtration"
  | "forbidden"
  | "reply"
  | "reply-all"
  | "grid"
  | "tree"
  | "demo"
  | "test"
  | "reduce"
  | "graph"
  | "bar"
  | "line"
  | "play"
  | "pay"
  | "reminder"
  | "wand"
  | "id"
  | "role"
  | "log"
  | "key"
  | "announcement"
  | "divide"
  | "times"
  | "minus2"
  | "plus2"
  | "share"
  | "syndemos"
  | "syndemosWithText"
  | "api"
  | "folder"
  | "exchange"

  // DiscountType
  | "formation"
  | "setup"
  | "user"
  | "users"
  | "company"
  | "entry_import"
  | "total"
  | "whitemark"
  | "backup"

  // Question exchange type
  | "expert_sendQuestion"
  | "company_sendBackQuestion"
  | "expert_sendQuestion_withTextFile"
  | "company_questionCompletedRecap"
  | "expert_questionRecap";

type Props = {
  icon?: IconList & string;
  size?: number;
  className?: string;
  color?: CssVariablesType;
};

export function IconButtonIcons({ icon, size = 18, className, color }: Props) {
  const [computedColor, setComputedColor] = useState(color && "var(--color-" + color + ")");

  useEffect(() => {
    setComputedColor(color && "var(--color-" + color + ")");
  }, [color]);

  return (
    <>
      {icon == "door" && <BsDoorOpen className={classNames("m-auto", className)} size={size} color={computedColor} />}
      {icon == "stop" && <BiBlock className={classNames("m-auto", className)} size={size} color={computedColor} />}
      {icon == "helpBook" && <GrHelpBook size={size} className={className} color={computedColor} />}
      {icon == "stopCircle" && <FaRegStopCircle size={size} className={className} color={computedColor} />}
      {icon == "total" && <IoWallet size={size} className={className} color={computedColor} />}
      {(icon == "setting" || icon == "setup") && <IoIosSettings size={size} className={className} color={computedColor} />}
      {icon == "formation" && <FaGraduationCap size={size} className={className} color={computedColor} />}
      {icon == "reply" && <LuReply size={size} className={className} color={computedColor} />}
      {icon == "reply-all" && <LuReplyAll size={size} className={className} color={computedColor} />}
      {icon == "whitemark" && <CiBookmark size={size} className={className} color={computedColor} />}
      {icon == "key" && <FaKey size={size} className={className} color={computedColor} />}
      {icon == "shop" && <IoStorefrontSharp size={size} className={className} color={computedColor} />}
      {icon == "announcement" && <GrAnnounce size={size} className={className} color={computedColor} />}
      {icon == "double-check" && <BsCheckAll size={size} className={className} color={computedColor} />}
      {/*  ACTIONS */}
      {icon == "play" && <FaPlay size={size} className={className} color={computedColor} />}
      {icon == "minus" && <PiMinusCircleFill size={size} className={className} color={computedColor} />}
      {icon == "divide" && <FaDivide size={size} className={className} color={color} />}
      {icon == "times" && <FaTimes size={size} className={className} color={color} />}
      {icon == "minus2" && <FaMinus size={size} className={className} color={color} />}
      {icon == "plus2" && <FaPlus size={size} className={className} color={color} />}
      {icon == "update" && <GrUpdate size={size} className={className} color={computedColor} />}
      {icon == "add" && <MdAddCircle size={size} className={className} color={computedColor} />}
      {icon == "close" && <IoClose size={size} className={className} color={computedColor} />}
      {icon == "edit" && <FiEdit size={size} className={className} color={computedColor} />}
      {icon == "trash" && <HiOutlineTrash size={size} strokeWidth={2} className={className} color={computedColor} />}
      {icon == "archive" && <BsArchive size={size} className={className} color={computedColor} />}
      {icon == "search" && <FaSearch size={size} className={className} color={computedColor} />}
      {icon == "check" && <BsCheckLg size={size} className={className} color={computedColor} />}
      {icon == "menu" && <IoMenu size={size} className={className} color={computedColor} />}
      {icon == "menu-fold" && <RiMenuFoldLine size={size} className={className} color={computedColor} />}
      {icon == "menu-search" && <RiMenuSearchLine size={size} className={className} color={computedColor} />}
      {(icon == "eraser" || icon == "erease") && <BsFillEraserFill size={size} className={className} color={computedColor} />}
      {icon == "send" && <IoIosSend size={size} className={className} color={computedColor} />}
      {icon == "return" && <RiArrowGoBackLine size={size} className={className} color={computedColor} />}
      {icon == "previous" && <BsArrowLeft size={size} className={className} color={computedColor} />}
      {icon == "next" && <BsArrowRight size={size} className={className} color={computedColor} />}
      {icon == "arrow-down" && <AiOutlineArrowDown size={size} className={className} color={computedColor} />}
      {icon == "save" && <FaSave size={size} className={className} color={computedColor} />}
      {icon == "external-link" && <LuExternalLink size={size} className={className} color={computedColor} />}
      {icon == "link" && <FaLink size={size} className={className} color={computedColor} />}
      {icon == "unlink" && <FaLinkSlash size={size} className={className} color={computedColor} />}
      {icon == "power-off" && <TbPower size={size} className={className} color={computedColor} />}
      {icon == "alarm" && <IoIosAlarm size={size} className={className} color={computedColor} />}
      {icon == "sort" && <BiSortDown size={size} className={className} color={computedColor} />}
      {icon == "merge" && <PiGitMerge size={size} className={className} color={computedColor} />}
      {icon == "forbidden" && <BiNoEntry size={size} className={className} color={computedColor} />}
      {(icon == "demo" || icon == "test") && <RiTestTubeFill size={size} className={className} color={computedColor} />}
      {icon == "share" && <IoShareSocial size={size} className={className} color={computedColor} />}
      {icon == "api" && <AiOutlineApi size={size} className={className} color={computedColor} />}
      {icon == "folder" && <FaFolder size={size} className={className} color={computedColor} />}
      {icon == "exchange" && <LiaExchangeAltSolid size={size} className={className} color={computedColor} />}

      {icon == "attachment" && <ImAttachment size={size} className={className} color={computedColor} />}
      {icon == "regularisation" && <BsFillClipboard2CheckFill size={size} className={className} color={computedColor} />}
      {icon == "mail-sended" && <IoMdMailUnread size={size} className={className} color={computedColor} />}
      {icon == "password-changed" && <MdPassword size={size} className={className} color={computedColor} />}
      {(icon == "download" || icon == "entry_import") && <FiDownload size={size} className={className} color={computedColor} />}
      {icon == "upload" && <LuUpload size={size} className={className} color={computedColor} />}
      {icon == "file" && <IoDocumentOutline size={size} className={className} color={computedColor} />}
      {icon == "warning" && <IoIosWarning size={size} className={className} color={computedColor} />}
      {(icon == "details" || icon == "eye") && <HiEye size={size} className={className} color={computedColor} />}
      {icon == "copy" && <LuCopyPlus size={size} className={className} color={computedColor} />}
      {icon == "copyText" && <FaCopy size={size} className={className} color={computedColor} />}
      {icon == "comment" && <FaCommentDots size={size} className={className} color={computedColor} />}
      {icon == "pdf" && <BsFillFileEarmarkPdfFill size={size} className={className} color={computedColor} />}
      {icon == "configuration" && <BsGearFill size={size} className={className} color={computedColor} />}
      {icon == "plug" && <FaPlug size={size} className={className} color={computedColor} />}
      {icon == "documentation" && <FaBook size={size} className={className} color={computedColor} />}
      {icon == "tag" && <FaTag size={size} className={className} color={computedColor} />}
      {icon == "people" && <BsPeopleFill size={size} className={className} color={computedColor} />}
      {icon == "lock-open" && <HiLockOpen size={size} className={className} color={computedColor} />}
      {icon == "lock" && <HiLockClosed size={size} className={className} color={computedColor} />}
      {icon == "import" && <IoDocumentOutline size={size} className={className} color={computedColor} />}
      {icon == "refresh" && <LuRefreshCcw size={size} className={className} color={computedColor} />}

      {icon == "filtration" && <FaFilter size={size} className={className} color={computedColor} />}
      {(icon == "notification" || icon == "reminder") && <BsBellFill size={size} className={className} color={computedColor} />}
      {icon == "noNotification" && <BsBellSlashFill size={size} className={className} color={computedColor} />}
      {icon == "history" && <MdHistory size={size} className={className} color={computedColor} />}
      {icon == "discount" && <TbDiscount2 size={size} className={className} color={computedColor} />}
      {icon == "grid" && <FaGripHorizontal size={size} className={className} color={computedColor} />}
      {icon == "tree" && <TbBinaryTree size={size} className={className} color={computedColor} />}
      {icon == "reduce" && <BsArrowsAngleContract size={size} className={className} color={computedColor} />}
      {(icon === "graph" || icon === "bar") && <IoBarChart size={size} className={className} color={computedColor} />}
      {icon === "line" && <BsGraphUp size={size} className={className} color={computedColor} />}
      {icon === "calendar" && <LuCalendarDays size={size} className={className} color={computedColor} />}
      {icon === "pay" && <FaFileInvoiceDollar size={size} className={className} color={computedColor} />}
      {icon == "wand" && <FaWandMagicSparkles size={size} className={className} color={computedColor} />}

      {/*  INFOS */}
      {icon == "user" && <FaUser size={size} className={className} color={computedColor} />}
      {icon == "users" && <FaUsers size={size} className={className} color={computedColor} />}
      {icon == "name" && <BiRename size={size} className={className} color={computedColor} />}
      {icon == "marker" && <TiSortAlphabetically size={size} className={className} color={computedColor} />}
      {icon == "phone" && <BsFillTelephoneFill size={size} className={className} color={computedColor} />}
      {icon == "mail" && <SiMaildotru size={size} className={className} color={computedColor} />}
      {icon == "computer" && <MdOutlineComputer size={size} className={className} color={computedColor} />}
      {icon == "restore" && <MdOutlineSettingsBackupRestore size={size} className={className} color={computedColor} />}
      {icon == "question" && <MdQuestionMark size={size} className={className} color={computedColor} />}
      {icon == "accountingFirm" && <FaBuildingColumns size={size} className={className} color={computedColor} />}
      {icon == "company" && <BsBuildingsFill size={size} className={className} color={computedColor} />}
      {icon == "identification" && <HiIdentification size={size} className={className} color={computedColor} />}
      {icon == "code" && <IoBarcodeOutline size={size} className={className} color={computedColor} />}
      {(icon == "rythm" || icon == "date") && <BsFillCalendarFill size={size} className={className} color={computedColor} />}
      {icon == "money" && <FaMoneyBillWaveAlt size={size} className={className} color={computedColor} />}
      {icon == "role" && <FaUserCog size={size} className={className} color={computedColor} />}
      {icon == "log" && <FaPowerOff size={size} className={className} color={computedColor} />}

      {/* ADRESSE */}
      {icon == "location" && <IoLocationSharp size={size} className={className} color={computedColor} />}
      {icon == "address" && <HiMail size={size} className={className} color={computedColor} />}
      {icon == "city" && <FaCity size={size} className={className} color={computedColor} />}
      {icon == "country" && <BsFlagFill size={size} className={className} color={computedColor} />}
      {icon == "id" && <FaAddressCard size={size} className={className} color={computedColor} />}

      {/* QUESTION*/}
      {icon == "pending" && <LuCalendar size={size} className={className} color={computedColor} />}
      {icon == "toProcess" && <TbListCheck size={size} className={className} color={computedColor} />}

      {icon == "syndemos" && (
        <Shape color="white" size={size}>
          <LogoSyndemosWithoutText className={className} />
        </Shape>
      )}
      {icon == "syndemosWithText" && (
        <Shape color="white" size={size}>
          <LogoSyndemosWithText className={className} />
        </Shape>
      )}
      {/* QUESTION EXCHANGE TYPE*/}
      {icon == "expert_sendQuestion" && <HiOutlineBuildingLibrary size={size} className={className} color={computedColor ?? "var(--color-active)"} />}
      {icon == "company_sendBackQuestion" && <BsBuildings size={size} className={className} color={computedColor ?? "var(--color-secondary)"} />}
      {icon == "expert_sendQuestion_withTextFile" && <PiMicrosoftWordLogoFill size={size} className={className} color={computedColor} />}
      {icon == "company_questionCompletedRecap" && <MdOutlineSummarize size={size} className={className} color={computedColor} />}
      {icon == "expert_questionRecap" && <PiListChecksFill size={size} className={className} color={computedColor} />}
    </>
  );
}
